
/**
 * Function used to filter out console warning messages that should not get reported to airbrake
 * Function returns bool value (true if airbrake should notify)
 * @param {*} message , console message to report to airbrake
 */
function notifyAirbrakeWarnings(message) {
  let returnValue = true;
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(message)) returnValue = false;
  if (message?.includes('This browser does not support audio output selection.')) returnValue = false;
  if (message?.includes('Unable to set audio output devices. InvalidArgumentError: Devices not found: default')) returnValue = false;
  if (message?.includes('Cannot connect to insights.')) returnValue = false;

  return returnValue;
}

/**
 * Function used to filter out console werror messages that should not get reported to airbrake
 * Function returns bool value (true if airbrake should skip notifying)
 * @param {*} message , console message to report to airbrake
 */
function skipAirbrakeErrors(notice) {
  let returnValue = false;
    if (notice == 'Error') returnValue = true;
    if (notice.errors.some(err => ['failed to fetch', 'failed to load'].some(msg => msg.includes(err.message?.toLowerCase())))) returnValue = true;

    return returnValue;
}

export {
  notifyAirbrakeWarnings,
  skipAirbrakeErrors
}
